import React, {useEffect, useRef, useState} from "react";
import UController from "components/Controller/UController";
import AsyncLoaderWrapper from "views/AsyncLoaderWrapper";
import Sector from "components/ClassWrapper/Sector";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import type {Dict} from "Utils";
import {
    getFullNameWithPrefix,
    getLocalDateStrFromISO,
    MATERIAL_TABLE_LOCALIZATION_FR, printIsoDateTime,
    printIsoInterval,
    reduceDict
} from "Utils";
import moment from "moment";
import type {TimeString} from "components/ClassWrapper/TimeClasses";
import CompleteRdv, { CR_SORT_BY_DATE_TIME_SESSION } from "components/ClassWrapper/CompleteRdv";
import MaterialTable from "material-table";
import ULiveFeed from "components/Controller/ULiveFeed";
import {hasEnded, hasStarted, hasStartedNotEnded} from "components/ClassWrapper/TimeClasses";
import DateRangeSelector from "components/picker/DateRangeSelector";
import SectorBadge from "./planning/SectorBadge";
import { Print } from "@material-ui/icons";
import { generateConsultationsTasksPDF } from "tools/pdf_generators";

type MedPrepTasksDictionary = {
    sectors: Dict<Sector>
}

const MedPrepTasksWrapper = ({dictionary}: { dictionary?: MedPrepTasksDictionary }) => {
    const [dateRange, setDateRange] = useState({
        startDate: moment().toISOString().substring(0, 10),
        endDate: moment().toISOString().substring(0, 10)
    });
    const [tasks, setTasks] = useState([]);
    const onDateRangeChosen = (startDate: TimeString, endDate: TimeString) => {
        if (!!startDate && !!endDate && (dateRange.startDate !== startDate || dateRange.endDate !== endDate))
            setDateRange({startDate, endDate});
    };
    const tableRef = useRef();
    useEffect(() => {
        UController.appointment.getAllMedPrepableRdvsInPeriod(dateRange.startDate, dateRange.endDate)
                .then(crdvs => crdvs.filter(crdv => !crdv.rdv.realCancel)) // Filter out canceled rdv
                .then(setTasks)
                .catch(error => {
                    console.error(error);
                    alert("Impossible de charger les tâches. Une erreur est survenue");
                });
    }, [dateRange]);

    useEffect(() => {
        const liveFeed = ULiveFeed.todayRdvs.subscribe((latestRdvChange: CompleteRdv) => {
            const previousRdv = tasks.find((task: CompleteRdv): boolean => task.rdv.id === latestRdvChange.rdv.id);
            if (!previousRdv) {
                // New rdv
                if (latestRdvChange.rdv.sessionDay >= dateRange.startDate &&
                        (
                                (!latestRdvChange.rdv.canPrePrepMed && latestRdvChange.rdv.sessionDay <= dateRange.endDate)
                                || (latestRdvChange.rdv.canPrePrepMed && latestRdvChange.rdv.sessionDay <= moment(dateRange.endDate).add(1, "day").toISOString(true))
                        )) {
                    setTasks(tasks.concat(latestRdvChange));
                }
            } else {
                const rdvDeletedOrMoved = !latestRdvChange.rdv.enabled
                        || latestRdvChange.rdv.sessionDay < dateRange.startDate
                        || latestRdvChange.rdv.sessionDay >= moment(dateRange.endDate).add(1, "day").toISOString(true);
                setTasks(tasks.map(r =>
                        // If not the recently changed rdv
                        r.rdv.id !== latestRdvChange.rdv.id ||
                        // If we already have the latest change
                        r.rdv.lastModifiedDateTime >= latestRdvChange.rdv.lastModifiedDateTime
                                ? r
                                : rdvDeletedOrMoved ? null : latestRdvChange
                )
                        .filter(s => s !== null))
            }
        });
        return liveFeed.unsubscribe;
    }, [dateRange, tasks]);

    return (
            <Row className="m-3">
                <Col>
                    <Card outline={true} color="primary">
                        <CardHeader className="d-flex align-items-baseline">
                            <h2 className="text-white mr-auto">
                                Tâches prévues
                            </h2>
                            <div style={{marginTop: 5}}>
                                <DateRangeSelector
                                    reloadAuto 
                                    horizon={{
                                        from: moment(dateRange.startDate),
                                        to: moment(dateRange.endDate)
                                    }} 
                                    popupPosition="right"
                                    definedPeriodsProposals="short"
                                    intervalFormat="literal"
                                    handleSubmit={({from, to}) => onDateRangeChosen(from.toISOString(true).substring(0, 10), to.toISOString(true).substring(0, 10))}/>
                            </div>
                        </CardHeader>
                        <CardBody className="d-flex flex-column justify-content-start">
                            <MaterialTable
                                    data={tasks}
                                    columns={[
                                        {
                                            field: "patient",
                                            title: "Patient",
                                            customSort: (data1, data2) => data2.patient.firstName.localeCompare(data1.patient.lastName),
                                            render: (rowData: CompleteRdv): React.ReactNode =>
                                                    <span>{getFullNameWithPrefix(rowData.patient)}</span>,
                                            customFilterAndSearch: (filter: string, rowData: CompleteRdv) =>
                                                    rowData.patient.firstName.toLowerCase().includes(filter.toLowerCase())
                                                    || rowData.patient.lastName.toLowerCase().includes(filter.toLowerCase()),
                                        },
                                        {
                                            field: "patient.externalId.value",
                                            title: "IPP",
                                        },
                                        {
                                            field: "patient.comment",
                                            title: "Note",
                                        },
                                        {
                                            field: "protocol.name",
                                            title: "Protocole",
                                            customSort: (data1, data2) => data2.protocol.name.localeCompare(data1.protocol.name),
                                        },
                                        {
                                            field: "protocol.sectorId",
                                            title: "Spécialité",
                                            lookup: reduceDict(dictionary.sectors, "name"),
                                            render: (rowData: CompleteRdv): React.ReactHTMLElement => <SectorBadge sector={dictionary.sectors[rowData.protocol.sectorId]} />
                                        },
                                        {
                                            field: "rdv.sessionDay",
                                            title: "Date de RDV",
                                            render: (rowData: CompleteRdv): React.ReactNode =>
                                                    <span>{getLocalDateStrFromISO(rowData.rdv.sessionDay, true)}</span>,
                                            filtering: false,
                                            customSort: CR_SORT_BY_DATE_TIME_SESSION,
                                            defaultSort: "asc",
                                        },
                                        {
                                            field: "rdv.session",
                                            title: "Horaire RDV prévu",
                                            render: (rowData: CompleteRdv): React.ReactNode =>
                                                    <span>{printIsoInterval(rowData.rdv.session)}</span>,
                                            filtering: false,
                                            customSort: CR_SORT_BY_DATE_TIME_SESSION,
                                        },
                                        {
                                            field: "rdv.canPrePrepMed",
                                            title: "Anticipé",
                                            type: "boolean",
                                            customSort: (data1, data2) => Number(data2.rdv.canPrePepMed) - Number(data1.rdv.canPrePepMed)
                                        },
                                        {
                                            field: "rdv.realSession",
                                            title: "Patient installé",
                                            type: "boolean",
                                            render: (rowData: CompleteRdv): React.ReactNode =>
                                                    <i className={hasStarted(rowData.rdv.realInstallation) ? "fas fa-2x fa-check-circle text-success" : "fas fa-2x fa-minus-circle text-muted"}/>,
                                            customFilterAndSearch: (filter: string, rowData: CompleteRdv) => filter === "checked" ? !!rowData.rdv.realSession : !rowData.rdv.realSession,
                                            customSort: (data1, data2) => Number(!!data2.rdv?.realInstallation?.start) - Number(!!data1.rdv?.realInstallation?.start),
                                        },
                                        {
                                            field: "rdv.go",
                                            title: "Feu vert",
                                            customSort: (data1, data2) => Number(data2.rdv.inNeedConsultation) - Number(data1.rdv.inNeedConsultation),
                                            render: (rowData: CompleteRdv): React.ReactNode =>
                                                <i className={!rowData.rdv.inNeedConsultation
                                                    ? "fas fa-2x fa-circle text-muted"
                                                    : !rowData.rdv.goMoment
                                                        ? "fas fa-2x fa-question-circle text-info"
                                                        : rowData.rdv.go
                                                            ? "fas fa-2x fa-check-circle text-success"
                                                            : "fas fa-2x fa-times-circle text-danger"}
                                                />,
                                            lookup: {
                                                "O": "Oui",
                                                "N": "Non",
                                                "-": "Sans consultation",
                                                "?": "A consulter"
                                            },
                                            customFilterAndSearch: (filter: string[], rowData: CompleteRdv) =>
                                                    filter.length === 0 ? true : filter.some(value => {
                                                        switch (value) {
                                                            case "O":
                                                                return rowData.rdv.goMoment && rowData.rdv.go;
                                                            case "N":
                                                                return rowData.rdv.goMoment && !rowData.rdv.go;
                                                            case "S":
                                                                return !rowData.rdv.inNeedConsultation;
                                                            case "?":
                                                                return !rowData.rdv.goMoment;
                                                            default:
                                                                return false;
                                                        }
                                                    })
                                        },
                                        {
                                            field: "rdv.goMoment",
                                            title: "Feu vert quand?",
                                            customSort: (data1, data2) => data2.rdv?.goMoment?.localeCompare(data1.rdv?.goMoment),
                                            render: (rowData: CompleteRdv): React.ReactNode =>
                                                !rowData.rdv.goMoment ? "?" : `${printIsoDateTime(rowData.rdv.goMoment)}`,
                                            filtering: false,
                                        },
                                        {
                                            field: "rdv.realMedPrep",
                                            title: "Progression",
                                            customSort: (data1, data2) => data2.rdv.realMedPrep?.start?.localeCompare(data1.rdv.realMedPrep?.start),
                                            render: (rowData: CompleteRdv): React.ReactNode =>
                                                    <span>{printIsoInterval(rowData.rdv.realMedPrep)}</span>,
                                            lookup: {
                                                "P": "Pas pris en compte",
                                                "C": "Pris en compte",
                                                "T": "Sortie de pharmacie"
                                            },
                                            customFilterAndSearch: (filter: string[], rowData: CompleteRdv) =>
                                                    filter.length === 0 ? true : filter.some(value => {
                                                        switch (value) {
                                                            case "P":
                                                                return !hasStarted(rowData.rdv.realMedPrep);
                                                            case "C":
                                                                return hasStartedNotEnded(rowData.rdv.realMedPrep);
                                                            case "T":
                                                                return hasEnded(rowData.rdv.realMedPrep);
                                                            default:
                                                                return false;
                                                        }
                                                    }),
                                        },
                                        {
                                            field: "rdv.realMedReception",
                                            title: "Médicament reçu ?",
                                            render: (rowData: CompleteRdv): React.ReactNode =>
                                                <span>{printIsoDateTime(rowData.rdv.realMedReception)}</span>,
                                            lookup: {
                                                "O": "Reçu",
                                                "N": "Pas encore reçu",
                                            },
                                            customSort: (data1, data2) => data2.rdv?.realMedReception?.localeCompare(data1.rdv?.realMedReception),
                                            customFilterAndSearch: (filter: string[], rowData: CompleteRdv) =>
                                                filter.length === 0 ? true : filter.some(value => {
                                                    switch (value) {
                                                        case "O":
                                                            return !!rowData.rdv.realMedReception;
                                                        case "N":
                                                            return !rowData.rdv.realMedReception;
                                                        default:
                                                            return false;
                                                    }
                                                }),
                                        }
                                    ]}
                                    actions={[
                                        {
                                            icon: () => <Print/>,
                                            tooltip: "Imprimer les consultations",
                                            isFreeAction: true,
                                            onClick: () => {
                                                const filteredTasks = !tableRef.current ? tasks : tableRef.current.state.data;
                                                generateConsultationsTasksPDF(dateRange, filteredTasks, dictionary.sectors);
                                            }
                                        },
                                        (rowData: CompleteRdv) => {
                                            const rdv = rowData.rdv;
                                            const disabled = (rdv.inNeedConsultation && (!rdv.goMoment || !rdv.go))
                                                    || hasEnded(rowData.rdv.realMedPrep);
                                            const hasStartedMedPrep = hasStarted(rdv.realMedPrep);
                                            return ({
                                                icon: () => <div><i className={`text-success ${!hasStartedMedPrep ? "fas fa-play-circle" : "fas fa-truck-loading"} ${disabled ? "text-muted" : ""}`}/></div>,
                                                tooltip: disabled ? "Aucune action possible" : !hasStartedMedPrep ? 'Prendre en compte' : 'Marquer la sortie de pharmacie',
                                                onClick: disabled ? () => {} : (event, rowData: CompleteRdv) => {
                                                    if (!hasStartedMedPrep) {
                                                        if (window.confirm(`Prenez-vous en compte la préparation de médicament pour ${getFullNameWithPrefix(rowData.patient)}?`)) {
                                                            UController.appointment.startDrugMixing(rowData.rdv.id)
                                                                    .catch(error => {
                                                                        console.error(error);
                                                                        alert("Impossible de marquer l'état de la tâche. Une erreur est survenue");
                                                                    });
                                                        }
                                                    } else if (!hasEnded(rdv.realMedPrep)) {
                                                        if (window.confirm(`Confirmez-vous la sortie de pharmacie de médicament pour ${getFullNameWithPrefix(rowData.patient)}?`)) {
                                                            UController.appointment.finishDrugMixing(rowData.rdv.id)
                                                                    .catch(error => {
                                                                        console.error(error);
                                                                        alert("Impossible de marquer l'état de la tâche. Une erreur est survenue");
                                                                    });
                                                        }
                                                    }
                                                },
                                            });
                                        }
                                    ]}
                                    options={{
                                        showTitle: false,
                                        columnsButton: true,
                                        exportButton: false,
                                        search: false,
                                        filtering: true,
                                        sorting: true,
                                        draggable: false,
                                        paging: true,
                                        showEmptyDataSourceMessage: true,
                                        actionsColumnIndex: -1
                                    }}
                                    localization={MATERIAL_TABLE_LOCALIZATION_FR}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
    );
}

const MedPrepTasks = () => (
        <AsyncLoaderWrapper loader={() => Promise.all([
            UController.sector.getDict()
        ]).then(([sectors]) =>
                ({
                    dictionary: {
                        sectors,
                    }
                })
        )}
                            onLoadingMessage={"En cours de chargement de la liste de tâches du jour"}
        >
            <MedPrepTasksWrapper/>
        </AsyncLoaderWrapper>
);

export default MedPrepTasks;