import { CYCLE_STATUS } from "components/ClassWrapper/Cycle";
import moment from "moment";
import { useEffect, useState } from "react";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import { Dict, getFullNameWithPrefix, logErrorGroup, printIsoDate, printIsoInterval } from "Utils";
import { UnixInterval, DateString, LocalDateInterval } from "components/ClassWrapper/TimeClasses";
import Place from "./Place";
import CompleteRdv from "components/ClassWrapper/CompleteRdv";
import Material from "components/ClassWrapper/Material";
import Patient from "components/ClassWrapper/Patient";

type OverlappingRdvEvent = {
    date: DateString,
    place: Material,
    rdvs: CompleteRdv[]
}

/**
 * A patient should have at most one rdv per day
 */
type PatientMultiRdvEvent = {
    patient: Patient,
    date: DateString,
    rdvs: CompleteRdv[]
}

type WarningLog = {
    overlappingRdvs?: OverlappingRdvEvent[],
    patientMultiRdvs?: PatientMultiRdvEvent[],
};

const isNonEmptyWarning = (warningLog?: WarningLog) => !!warningLog && (warningLog.overlappingRdvs?.length > 0 || warningLog.patientMultiRdvs?.length > 0);

const PlanningWarnCenter = ({
    completeRdvMap,
    selectedInterval,
    places
}: {
    completeRdvMap: Dict<CompleteRdv>,
    selectedInterval: UnixInterval,
    places: Place[]
}) => {
    const [loading, setLoading]: [Boolean,] = useState(true);
    const [warningLog, setWarningLog]: [WarningLog,] = useState(null);

    useEffect(() => {
        if (!completeRdvMap || !selectedInterval) return;
        setLoading(true);
        const horizon = {
            start: moment(selectedInterval.start).format(moment.HTML5_FMT.DATE),
            end: moment(selectedInterval.end).format(moment.HTML5_FMT.DATE),
        }
        Promise.resolve(Object.values(completeRdvMap))
            .then(crdvs => Promise.all([
                Promise.resolve().then(() => findOverlappingRdvs(crdvs, horizon)),
                Promise.resolve().then(() => findPatientMultiRdvs(crdvs, horizon)),
            ]))
            .then(([overlappingRdvs, patientMultiRdvs]) => {
                setWarningLog({
                    overlappingRdvs,
                    patientMultiRdvs
                });
            })
            .catch(e => logErrorGroup(e, "detect_warnings"))
            .finally(() => setLoading(false));
    }, [completeRdvMap, selectedInterval]);

    const hasWarnings = isNonEmptyWarning(warningLog);
    return (
        <div id="planning-warn-center">
            <span>Alertes : </span>
            <span className="btn_mode"
                style={{ cursor: "pointer" }}
                id="planning-warn-center__opener">
                <i className={`fas ${loading ? "fa-spinner fa-spin" : hasWarnings ? "fa-exclamation-triangle text-danger" : "fa-smile text-success"}`}></i>
            </span>
            {
                hasWarnings &&
                <UncontrolledPopover trigger="click" placement="bottom" target="planning-warn-center__opener">
                    <PopoverBody>
                        <ol style={{
                            paddingLeft: "1.5rem",
                            overflowY: "auto",
                            maxHeight: "50vh"
                        }}>
                            {
                                /* Use first rdv id as subkey since multiple overlaps may happens on same day and same place, but each rdv belongs to at most one event */
                                warningLog.overlappingRdvs?.map(event => <li key={`overlapping-rdv-event-${event.date}-${event.place.id}-${event.rdvs[0].id}`}>
                                    Chevauchement sur <span className="font-weight-bold text-info">{event.place.number}</span> le <span className="font-weight-bold text-info">{printIsoDate(event.date, true)}</span> pour <span className="font-weight-bold text-info">{event.rdvs.map(r => getFullNameWithPrefix(r.patient)).join(", ")}</span>
                                </li>)
                            }
                            {
                                warningLog.patientMultiRdvs?.map(event => <li key={`patient-multi-rdv-event-${event.patient.id}-${event.date}`}>
                                    <span className="font-weight-bold text-info">{getFullNameWithPrefix(event.patient)}</span> a <span className="font-weight-bold text-info">{event.rdvs.length}</span> RDV le <span className="font-weight-bold text-info">{printIsoDate(event.date, true)}</span>: <span className="font-weight-bold text-info">{event.rdvs.map(r => printIsoInterval(r.rdv.session)).join(", ")}</span>
                                </li>)
                            }
                        </ol>
                    </PopoverBody>
                </UncontrolledPopover>
            }
        </div>
    )
}

export default PlanningWarnCenter;

const findOverlappingRdvs = (
    crdvs?: CompleteRdv[],
    horizon?: LocalDateInterval
): OverlappingRdvEvent[] => {
    if (!crdvs || crdvs.length === 0 || !horizon) return [];

    const rdv2dMap: {
        [key: string]: {
            date: DateString,
            place: Material,
            rdvs: CompleteRdv[],
        }
    } = {};
    for (const crdv of crdvs) {
        if (crdv.registerStatus === CYCLE_STATUS.TO_DELETE) continue;
        if (crdv.rdv.realCancel) continue;
        if (crdv.rdv.sessionDay < horizon.start || crdv.rdv.sessionDay > horizon.end) continue;
        const key = `${crdv.rdv.sessionDay}_${crdv.rdv.place.id}`;
        if (!rdv2dMap[key])
            rdv2dMap[key] = {
                date: crdv.rdv.sessionDay,
                place: crdv.rdv.place,
                rdvs: []
            };
        rdv2dMap[key].rdvs.push(crdv);
    }

    const events: OverlappingRdvEvent[] = [];

    for (const key in rdv2dMap) {
        if (rdv2dMap[key].rdvs.length < 2) continue;
        const { date, place, rdvs } = rdv2dMap[key];
        rdvs.sort((rdv1, rdv2) => rdv1.rdv.session.start.localeCompare(rdv2.rdv.session.start));
        let overlappingRdvs: CompleteRdv[] = [];
        let lastCrdv: CompleteRdv = null;
        for (let i = 0; i < rdvs.length; i++) {
            const crdv = rdvs[i];
            if (overlappingRdvs.length === 0) {
                overlappingRdvs.push(crdv);
                lastCrdv = crdv;
                continue;
            } else {
                // Compare to last rdv
                // Overlapping event may span over multiple rdvs
                // Example
                // Rdv[0] ---[    [-------------------
                // Rdv[1] -------[    [---------------
                // Rdv[2] -----------[   [------------
                if (lastCrdv.rdv.session.end > crdv.rdv.session.start) {
                    overlappingRdvs.push(crdv);
                    lastCrdv = crdv;
                } else {
                    if (overlappingRdvs.length > 1) {
                        events.push({
                            date,
                            place,
                            rdvs: overlappingRdvs
                        });
                    }
                    overlappingRdvs = [crdv];
                    lastCrdv = crdv;
                }
            }
        }
        if (overlappingRdvs.length > 1) {
            events.push({
                date,
                place,
                rdvs: overlappingRdvs
            });
        }
    }

    return events;
}

const findPatientMultiRdvs = (
    crdvs?: CompleteRdv[],
    horizon?: LocalDateInterval
): PatientMultiRdvEvent[] => {
    if (!crdvs || crdvs.length === 0 || !horizon) return [];

    const rdv2dMap: {
        [key: string]: {
            date: DateString,
            patient: Patient,
            rdvs: CompleteRdv[],
        }
    } = {};
    for (const crdv of crdvs) {
        if (crdv.registerStatus === CYCLE_STATUS.TO_DELETE) continue;
        if (crdv.rdv.realCancel) continue;
        if (crdv.rdv.sessionDay < horizon.start || crdv.rdv.sessionDay > horizon.end) continue;
        const key = `${crdv.patient.id}_${crdv.rdv.sessionDay}`;
        if (!rdv2dMap[key])
            rdv2dMap[key] = {
                date: crdv.rdv.sessionDay,
                patient: crdv.patient,
                rdvs: []
            };
        rdv2dMap[key].rdvs.push(crdv);
    }

    const events: PatientMultiRdvEvent[] = [];

    for (const key in rdv2dMap) {
        if (rdv2dMap[key].rdvs.length < 2) continue;
        events.push(rdv2dMap[key]);
    }

    return events;
}