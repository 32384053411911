/**
 * Represent a {@link Rendezvous} in detail from {@link Cycle}
 */
import Rendezvous from "components/ClassWrapper/Rendezvous";
import Protocol from "components/ClassWrapper/Protocol";
import Patient from "components/ClassWrapper/Patient";
import Motive from "components/ClassWrapper/Motive";
import BasicModel from "components/ClassWrapper/BasicModel";

export default class CompleteRdv extends BasicModel {
    /**
     *
     * @param cycle
     * @param rdv pick from rendezvous list of cycle
     */
    constructor(cycle: {id: Number, motive: Motive | null, patient: Patient, protocol: Protocol},
                rdv: Rendezvous) {
        super({
            id: rdv.id,
            createdDateTime: rdv.createdDateTime,
            lastModifiedDateTime: rdv.lastModifiedDateTime,
            enabled: rdv.enabled
        });
        /**
         *
         * @type {Rendezvous}
         */
        this.rdv = rdv;
        /**
         *
         * @type {Patient}
         */
        this.patient = cycle.patient;
        /**
         *
         * @type {Protocol}
         */
        this.protocol = cycle.protocol;
        /**
         *
         * @type {Motive | null}
         */
        this.motive = cycle.motive;
        /**
         *
         * @type {string | null} from CYCLE_STATUS
         */
        this.registerStatus = rdv.registerStatus;
        /**
         *
         * @type {number}
         */
        this.cycleId = cycle.id;
    }

    /**
     * Construct object from server api's response
     * @param rdv
     * @param protocol
     * @param motive
     * @param patient
     * @param cycleId
     * @returns {CompleteRdv}
     */
    static fromServer = ({rdv, protocol, motive, patient, cycleId}: {
        rdv: Rendezvous,
        protocol: Protocol,
        patient: Patient,
        motive: Motive | null,
        cycleId: number
    }): CompleteRdv =>
        new CompleteRdv(
            {
                id: cycleId,
                patient,
                motive,
                protocol,
            },
            rdv)
}

const CR_SORT_BY_DATE_TIME = (dayField: String, intervalField: String) => (cr1: CompleteRdv, cr2: CompleteRdv) => cr1.rdv[dayField].localeCompare(cr2.rdv[dayField]) || cr1.rdv[intervalField].start.localeCompare(cr2.rdv[intervalField].start) || cr1.rdv[intervalField].end.localeCompare(cr2.rdv[intervalField].end);

export const CR_SORT_BY_DATE_TIME_SESSION = CR_SORT_BY_DATE_TIME("sessionDay", "session");