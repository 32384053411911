import React, {useEffect, useRef, useState} from "react";
import UController from "components/Controller/UController";
import AsyncLoaderWrapper from "views/AsyncLoaderWrapper";
import Sector from "components/ClassWrapper/Sector";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {
    getFullNameWithPrefix,
    getFullNameWithPrefixDoctor,
    getISODateFromISODateTime,
    getLocalDateStrFromISO,
    logErrorGroup,
    MATERIAL_TABLE_LOCALIZATION_FR,
    printIsoInterval,
    reduceDict
} from "Utils";
import moment from "moment";
import type {TimeString, DateString} from "components/ClassWrapper/TimeClasses";
import CompleteRdv, { CR_SORT_BY_DATE_TIME_SESSION } from "components/ClassWrapper/CompleteRdv";
import MaterialTable from "material-table";
import ULiveFeed from "components/Controller/ULiveFeed";
import RdvStatus from "components/ClassWrapper/RdvStatus";
import {AccountController} from "components/Controller/AuthProvider";
import {hasEnded, hasStarted} from "components/ClassWrapper/TimeClasses";
import { ROLE } from "Constants";
import { generateConsultationsTasksPDF } from "tools/pdf_generators";
import { Print } from "@material-ui/icons";
import DateRangeSelector from "components/picker/DateRangeSelector";
import SectorBadge from "views/planning/SectorBadge";

type ConsultationTasksWrapperProps = {
    sectorDict: {[key: String]: Sector}
}

function rdvSortDoctorNullFirst(r1: CompleteRdv, r2: CompleteRdv): Number {
    const d1 = r1.rdv.doctor, d2 = r2.rdv.doctor;
    if (!d1) return d2 ? -1 : 0;
    return !d2 ? 1 : d2.id - d1.id;
}

function rdvSortConsultationNullFirst(r1: CompleteRdv, r2: CompleteRdv): Number {
    const c1 = r1.rdv.consultation, c2 = r2.rdv.consultation;
    if (!c1) return c2 ? -1 : 0;
    return !c2 ? 1 : c2.start.localeCompare(c1.start);
}

function rdvSorter(r1: CompleteRdv, r2: CompleteRdv): Number {
    return r1.rdv.sessionDay.localeCompare(r2.rdv.sessionDay)
        || rdvSortDoctorNullFirst(r1, r2)
        || rdvSortConsultationNullFirst(r1, r2);
}

type DateRange = {
    startDate: DateString,
    endDate: DateString
}

const ConsultationTasksWrapper = ({sectorDict}: ConsultationTasksWrapperProps) => {
    
    const [dateRange, setDateRange] : [DateRange, ((dr: DateRange) => void)] = useState({
        startDate: getISODateFromISODateTime(moment().toISOString(true)),
        endDate: getISODateFromISODateTime(moment().toISOString(true))
    });
    const [tasks, setTasks] : [CompleteRdv[], (CompleteRdv[] => void)] = useState([]);
    const [doctorLookup, setDoctorLookup] : [{[key: String]: String}, (lookup: {[key: String]: String}) => void] = useState({});
    const tableRef = useRef();

    const onDateRangeChosen = (startDate: TimeString, endDate: TimeString) => {
        if (!!startDate && !!endDate && (dateRange.startDate !== startDate || dateRange.endDate !== endDate))
            setDateRange({startDate, endDate});
    };

    useEffect(() => {
        UController.appointment.getAllConsultableRendezvousInPeriod(dateRange.startDate, dateRange.endDate)
                .then(rdvs => {
                    // Filter out canceled rdv
                    rdvs = rdvs.filter(crdv => !crdv.rdv.realCancel);
                    rdvs.sort(rdvSorter);
                    setTasks(rdvs);
                    const lookup = {};
                    new Set(rdvs.map(r => r.rdv.doctor).filter(d => !!d))
                        .forEach(d => lookup[d.id] = getFullNameWithPrefixDoctor(d));
                    setDoctorLookup(lookup);
                })
                .catch(error => {
                    console.error(error);
                    alert("Impossible de charger les tâches. Une erreur est survenue");
                });
    }, [dateRange]);
    useEffect(() => {
        const liveFeed = ULiveFeed.todayRdvs.subscribe((latestRdvChange: CompleteRdv) => {
            const previousRdv = tasks.find((task: CompleteRdv): boolean => task.rdv.id === latestRdvChange.rdv.id);
            if (!previousRdv) {
                // New rdv
                if ((latestRdvChange.rdv.inNeedConsultation) && latestRdvChange.rdv.sessionDay >= dateRange.startDate
                        && !!latestRdvChange.rdv.enabled && !latestRdvChange.rdv.realCancel && !latestRdvChange.rdv.realSession?.end) {
                    setTasks(tasks.concat(latestRdvChange));
                }
            } else {
                const rdvDeletedOrMoved = !latestRdvChange.rdv.enabled || latestRdvChange.rdv.sessionDay < dateRange.startDate;
                setTasks(tasks.map(r =>
                        // If not the recently changed rdv
                        r.rdv.id !== latestRdvChange.rdv.id ||
                        // If we already have the latest change
                        r.rdv.lastModifiedDateTime >= latestRdvChange.rdv.lastModifiedDateTime
                                ? r
                                : rdvDeletedOrMoved ? null : latestRdvChange
                )
                        .filter(s => s !== null))
            }
        });
        return liveFeed.unsubscribe;
    }, [dateRange, tasks]);

    return (
            <Row className="m-3">
                <Col>
                    <Card outline={true} color="primary">
                        <CardHeader className="d-flex align-items-baseline">
                            <h2 className="text-white mr-auto">
                                Consultations prévues
                            </h2>
                            <div style={{marginTop: 5}}>
                                <DateRangeSelector
                                    reloadAuto 
                                    horizon={{
                                        from: moment(dateRange.startDate),
                                        to: moment(dateRange.endDate)
                                    }} 
                                    popupPosition="right"
                                    definedPeriodsProposals="short"
                                    intervalFormat="literal"
                                    handleSubmit={({from, to}) => onDateRangeChosen(from.toISOString(true).substring(0, 10), to.toISOString(true).substring(0, 10))}/>
                            </div>
                        </CardHeader>
                        <CardBody className="d-flex flex-column justify-content-start">
                            <MaterialTable
                                    data={tasks}
                                    tableRef={tableRef}
                                    columns={[
                                        {
                                            field: "patient",
                                            title: "Patient",
                                            render: (rowData: CompleteRdv): React.ReactNode =>
                                                    <span>{getFullNameWithPrefix(rowData.patient)}</span>,
                                            customFilterAndSearch: (filter: string, rowData: CompleteRdv) =>
                                                    rowData.patient.firstName.toLowerCase().includes(filter.toLowerCase())
                                                    || rowData.patient.lastName.toLowerCase().includes(filter.toLowerCase()),
                                        },
                                        {
                                            field: "patient.comment",
                                            title: "Note",
                                            filtering: true,
                                        },
                                        {
                                            field: "protocol.name",
                                            title: "Protocole",
                                        },
                                        {
                                            field: "protocol.sectorId",
                                            title: "Spécialité",
                                            lookup: reduceDict(sectorDict, "name"),
                                            render: (rowData: CompleteRdv): React.ReactHTMLElement => <SectorBadge sector={sectorDict[rowData.protocol.sectorId]} />
                                        },
                                        {
                                            field: "motive.name",
                                            title: "Motif",
                                        },
                                        {
                                            field: "rdv.comment",
                                            title: "Commentaire",
                                            filtering: false,
                                        },
                                        {
                                            field: "rdv.sessionDay",
                                            title: "Date de séance",
                                            render: (rowData: CompleteRdv): React.ReactNode =>
                                                    <span>{getLocalDateStrFromISO(rowData.rdv.sessionDay, true)}</span>,
                                            filtering: false,
                                            customSort: CR_SORT_BY_DATE_TIME_SESSION,
                                        },
                                        {
                                            field: "rdv.session",
                                            title: "Horaire RDV prévu",
                                            render: (rowData: CompleteRdv): React.ReactNode =>
                                                    <span>{printIsoInterval(rowData.rdv.session)}</span>,
                                            filtering: false,
                                            customSort: CR_SORT_BY_DATE_TIME_SESSION,
                                        },
                                        {
                                            field: "rdv.place.number",
                                            title: "Hébergement",
                                        },
                                        {
                                            field: "rdv.consultationInPlace",
                                            title: "Consultation sur place",
                                            type: "boolean",
                                        },
                                        {
                                            field: "rdv.realInstallation",
                                            title: "Patient installé",
                                            type: "boolean",
                                            render: (rowData: CompleteRdv): React.ReactNode =>
                                                    <i className={hasStarted(rowData.rdv.realInstallation) ? "fas fa-2x fa-check-circle text-success" : "fas fa-2x fa-minus-circle text-muted"}/>,
                                            customFilterAndSearch: (filter: string, rowData: CompleteRdv) => filter === "checked" ? !!hasStarted(rowData.rdv.realInstallation) : !hasStarted(rowData.rdv.realInstallation),
                                        },
                                        {
                                            field: "rdv.doctor",
                                            title: "Médecin en charge",
                                            lookup: doctorLookup,
                                            render: (rowData: CompleteRdv): React.ReactNode =>
                                                    <span>{rowData.rdv.doctor && getFullNameWithPrefixDoctor(rowData.rdv.doctor)}</span>,
                                            customFilterAndSearch: (filter: String[], rowData: CompleteRdv) => filter.length === 0 || filter.includes(rowData.rdv.doctor?.id + ""),
                                        },
                                        {
                                            field: "rdv.consultation",
                                            title: "Horaire prévu",
                                            render: (rowData: CompleteRdv): React.ReactNode =>
                                                    <span>{rowData.rdv.consultationInPlace ? printIsoInterval(rowData.rdv.consultation) : "-"}</span>,
                                            filtering: false,
                                            sorting: false,
                                        },
                                        {
                                            field: "rdv.realConsultation",
                                            title: "Horaire réel",
                                            render: (rowData: CompleteRdv): React.ReactNode =>
                                                    <span>{printIsoInterval(rowData.rdv.realConsultation)}</span>,
                                            filtering: false,
                                            sorting: false,
                                        },
                                        {
                                            field: "rdv.go",
                                            title: "Feu vert",
                                            render: (rowData: CompleteRdv): React.ReactNode =>
                                                <i className={!rowData.rdv.inNeedConsultation
                                                    ? "fas fa-2x fa-circle text-muted"
                                                    : !rowData.rdv.goMoment
                                                        ? "fas fa-2x fa-question-circle text-info"
                                                        : rowData.rdv.go
                                                            ? "fas fa-2x fa-check-circle text-success"
                                                            : "fas fa-2x fa-times-circle text-danger"}
                                                />,
                                            lookup: {
                                                "O": "Oui",
                                                "N": "Non",
                                                "?": "A consulter"
                                            },
                                            customFilterAndSearch: (filter: string[], rowData: CompleteRdv) =>
                                                    filter.length === 0 ? true : filter.some(value => {
                                                        switch (value) {
                                                            case "O":
                                                                return rowData.rdv.goMoment && rowData.rdv.go;
                                                            case "N":
                                                                return rowData.rdv.goMoment && !rowData.rdv.go;
                                                            case "?":
                                                                return !rowData.rdv.goMoment;
                                                            default:
                                                                return false;
                                                        }
                                                    }),
                                            sorting: false,
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: () => <Print />,
                                            tooltip: "Imprimer",
                                            isFreeAction: true,
                                            onClick: () => {
                                                const filteredTasks = !tableRef.current ? tasks : tableRef.current.state.data;
                                                generateConsultationsTasksPDF(dateRange, filteredTasks, sectorDict);
                                            }
                                        },
                                        (rowData: CompleteRdv) => {
                                            const disabled = AccountController.getCurrentAccount().role !== ROLE.DOCTOR
                                                || hasStarted(rowData.rdv.realConsultation)
                                                || (!!rowData.rdv.doctor && rowData.rdv.doctor.id !== AccountController.getCurrentAccount().staff.id)
                                                || (!!rowData.rdv.inNeedConsultation && !!rowData.rdv.consultationInPlace && !hasStarted(rowData.rdv.realSession));
                                            return ({
                                                icon: () => <i className={"fas fa-play-circle text-success" + (disabled ? " text-muted" : "")}/>,
                                                tooltip: disabled ? "Action impossible" : "Commencer la consultation",
                                                onClick: disabled ? () => {} : (event, data: CompleteRdv) => {
                                                    if (window.confirm(`Commencer la consultation de ${getFullNameWithPrefix(data.patient)} ?`))
                                                        UController.appointment.setStatus(
                                                                data.rdv.id,
                                                                RdvStatus.ON_CONSULT
                                                        )
                                                                .catch(error => {
                                                                    console.error(error);
                                                                    window.alert("Impossible de marquer la charge. Une erreur est survenue");
                                                                })
                                                },
                                            });
                                        },
                                        (rowData: CompleteRdv) => {
                                            const disabled = AccountController.getCurrentAccount().role !== ROLE.DOCTOR
                                                || hasEnded(rowData.rdv.realSession)
                                                || !!rowData.rdv.realCancel
                                                || !hasStarted(rowData.rdv.realConsultation)
                                                || rowData.rdv.doctor?.id !== AccountController.getCurrentAccount().staff.id;
                                            return ({
                                                icon: () => <i
                                                        className={"fas fa-check-circle text-success" + (disabled ? " text-muted" : "")}/>,
                                                tooltip: disabled ? "Action impossible" : "Donner un feu vert",
                                                onClick: disabled ? () => {} : (event, data: CompleteRdv) => {
                                                    UController.appointment.toggleGo(
                                                            data.rdv.id,
                                                            true
                                                    )
                                                            .catch(error => {
                                                                console.error(error);
                                                                window.alert("Impossible de marquer la charge. Une erreur est survenue");
                                                            })
                                                },
                                            });
                                        },
                                        (rowData: CompleteRdv) => {
                                            const disabled = AccountController.getCurrentAccount().role !== ROLE.DOCTOR
                                                || hasEnded(rowData.rdv.realSession)
                                                || !!rowData.rdv.realCancel
                                                || !hasStarted(rowData.rdv.realConsultation)
                                                || rowData.rdv.doctor?.id !== AccountController.getCurrentAccount().staff.id;
                                            return ({
                                                icon: () => <i
                                                        className={"fas fa-times-circle text-danger" + (disabled ? " text-muted" : "")}/>,
                                                tooltip: disabled ? "Action impossible" : "Donner un feu rouge",
                                                onClick: disabled ? () => {} : (event, data: CompleteRdv) => {
                                                    UController.appointment.toggleGo(
                                                            data.rdv.id,
                                                            false
                                                    )
                                                            .catch(error => {
                                                                logErrorGroup(error, "rdv.toggle_go");
                                                                window.alert("Impossible de commencer la consultation. Une erreur est survenue");
                                                            })
                                                },
                                            });
                                        },
                                    ]}
                                    options={{
                                        showTitle: false,
                                        columnsButton: true,
                                        exportButton: false, exportDelimiter: ';', exportAllData: true,
                                        search: false,
                                        filtering: true,
                                        sorting: true,
                                        draggable: false,
                                        paging: true,
                                        showEmptyDataSourceMessage: true,
                                        actionsColumnIndex: -1
                                    }}
                                    localization={MATERIAL_TABLE_LOCALIZATION_FR}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
    );
}

const ConsultationTasks = () => (
        <AsyncLoaderWrapper loader={() => Promise.all([
            UController.sector.getDict()
        ]).then(([sectorDict]) =>
                ({
                    sectorDict
                })
        )}
                            onLoadingMessage={"En cours de chargement de la liste de tâches du jour"}
        >
            <ConsultationTasksWrapper/>
        </AsyncLoaderWrapper>
);

export default ConsultationTasks;