import { darken } from '@material-ui/core';
import { DeleteOutline, Edit, KeyboardArrowDown, KeyboardArrowUp, AddOutlined, VisibilityOff, UndoOutlined } from '@material-ui/icons';
import { getFullNameWithPrefix, getLocalDateStrFromISO, printIsoTime } from 'Utils';
import Cycle, { CYCLE_STATUS } from 'components/ClassWrapper/Cycle';
import Rendezvous from "components/ClassWrapper/Rendezvous";
import { useCallback, useState } from 'react';
import { styled } from 'styled-components';
import type { ModelId } from 'components/ClassWrapper/BasicModel';
import type { DateString } from 'components/ClassWrapper/TimeClasses';
import RegisterStatusSymbol from './RegisterStatusSymbol';

const Container = styled('div')`
    width: 300px;
    position: relative;
    z-index: 999;
    margin-left: 5px;
    span.opener{
        height: 40px;
        line-height: 40px;
        padding: 0px 5px;
        cursor: pointer;
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        transition: .2s;
        background: ${props => props.isOpen ? "rgba(0,0,0,.1)" : "inherit"};
        &:hover{
            background: rgba(0,0,0,.1);
        }
    }
    ul.suggestion-cycles-list{
        position: absolute;
        z-index: 1;
        bottom: 25px;
        left: 0;
        list-style: none;
        padding-left: 0px;
        width: 100%;
        background: #fff;
        border-radius: 3px;
        padding: 5px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        li.suggestion-cycles-item{
            font-size: 13px;
            display: flex;
            flex-direction: column;
            padding: 5px 0px;
            .main-items-container{
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .rdvs-items-list{
                span{
                    font-style: italic;
                }
            }
            .rdvs-items-list-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
        li.all-actions{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #525f7f;
            padding-top: 5px;
            margin-top: 5px;
        }
    }
`

const IconActions = styled('span')`
    cursor: pointer;
    svg{
        color: #fff;
        padding: 2px 4px; 
        border-radius: 2px; 
        background: ${props => props.background};
        &:hover{
            background: ${props => darken(props.background, .2)};
        }
        margin: 0.5px
    }
`

type Props = {
    label: String,
    cycles: Cycle[],
    onRdvsDeselectAll: () => void,
    onCycleAdd: () => void,
    onCycleDeselect: (cycleId: number) => void,
    onCycleUndo: (cycleIds: number[]) => void,
    onCycleDelete: (cycleIds: number[]) => void,
    onRdvDelete: (rdv: Rendezvous) => void,
    onRdvEdit: (rdvId: Number) => void,
    onRdvUndo: (rdv: Rendezvous, cycleId: number) => void,
    getPlaceLabel: (placeId: ModelId) => String,
    currentDate: DateString,
}

const CycleModifyViewer = (props: Props) => {

    const { label, cycles, onRdvsDeselectAll, onCycleAdd, onCycleDeselect, onCycleUndo, onCycleDelete, onRdvDelete, onRdvEdit, onRdvUndo, getPlaceLabel, currentDate } = props

    const [open, setOpen] = useState(true)

    const toggleOpen = useCallback(() => setOpen(o => !o), [setOpen])
    return (
        <Container isOpen={open}>
            <span className="opener font-weight-bold mr-2" onClick={toggleOpen}>
                {label}
                {!open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </span>
            {
                open && cycles.length > 0 &&
                <ul className='suggestion-cycles-list'>
                    {cycles.map(cycle => (
                        <li key={cycle.id} className={"suggestion-cycles-item"}>
                            <div className="main-items-container">
                                <div>
                                    <span>{getFullNameWithPrefix(cycle?.patient)}</span>
                                    {" "}
                                    <RegisterStatusSymbol registerStatus={cycle.registerStatus} />
                                </div>
                                <div className="icons-container">
                                    {cycle.registerStatus !== CYCLE_STATUS.TO_CREATE && <IconActions key={"cancel"} background={"#59515E"}><UndoOutlined onClick={() => onCycleUndo([cycle.id])} /></IconActions>}
                                    <IconActions key={"select"} background={"#ffc107"}><VisibilityOff onClick={() => onCycleDeselect(cycle.id)} /></IconActions>
                                    <IconActions key={"delete"} background={"#dc3545"}><DeleteOutline onClick={() => onCycleDelete([cycle.id])} /></IconActions>
                                </div>
                            </div>
                            <div className="rdvs-items-list">
                                {cycle.rendezvousList.map((rdv, k) => {
                                    const modifiable = rdv.sessionDay >= currentDate;
                                    const rdvNotCancelled = rdv.realCancel === null;
                                    return (
                                        <div key={k} className="rdvs-items-list-item">
                                            <div className="rdv-description-short" style={{ textDecoration: rdvNotCancelled ? "none" : "line-through 2px" }}>
                                                <span>{k + 1}. </span>
                                                {getPlaceLabel(rdv.place.id)}
                                                {" "}
                                                <span>{getLocalDateStrFromISO(rdv.sessionDay, true)} </span>
                                                <span>{printIsoTime(rdv.session.start)} - {printIsoTime(rdv.session.end)} </span>
                                                <RegisterStatusSymbol registerStatus={rdv.registerStatus}/>
                                            </div>
                                            {
                                                !rdv.realCancel &&
                                                <div className="icons-container">
                                                    {rdv.registerStatus !== CYCLE_STATUS.CONFIRMED && rdv.registerStatus !== CYCLE_STATUS.TO_CREATE
                                                        && <IconActions background={(rdvNotCancelled && modifiable) ? "#59515E" : "#dbdbdb"} key={"cancel"}><UndoOutlined onClick={() => (rdvNotCancelled && modifiable) && onRdvUndo(rdv, cycle.id)} /></IconActions>}
                                                    <IconActions background={(rdvNotCancelled && modifiable) ? "#2f80ed" : "#dbdbdb"} key={"edit"}><Edit onClick={() => (rdvNotCancelled && modifiable) && onRdvEdit(rdv.id)} /></IconActions>
                                                    <IconActions background={(rdvNotCancelled && modifiable) ? "#dc3545" : "#dbdbdb"} key={"delete"}><DeleteOutline onClick={() => (rdvNotCancelled && modifiable) && onRdvDelete(rdv)} /></IconActions>
                                                </div>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        </li>
                    ))}
                    <li className='all-actions'>
                        <span className='font-weight-bold'>Tous les RDVs :</span>
                        <div className="icons-container">
                            <IconActions background={"#2dce89"} onClick={onCycleAdd}><AddOutlined /></IconActions>
                            <IconActions background={"#ffc107"} onClick={onRdvsDeselectAll}><VisibilityOff /></IconActions>
                        </div>
                    </li>
                </ul>
            }
        </Container>
    )

}

export default CycleModifyViewer